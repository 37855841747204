import Upload from "./components/upload/upload.component";

function App() {
  return (
    <div>
      <div className="bg-amber-50 min-h-screen flex justify-center">
        <Upload />
      </div>

      <div className="py-6 bg-amber-50 flex flex-col justify-center items-center">
        <div className="max-w-xl border border-gray-200 w-96"></div>
        <div className="flex flex-row justify-center items-center space-x-8 pt-4">
          <p className="text-center text-gray-400 text-base">
            &copy; 2022 Vocab
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vocab.so/imprint"
            className="text-base text-gray-400 hover:text-gray-900"
          >
            Imprint
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vocab.so/privacy"
            className="text-base text-gray-400 hover:text-gray-900"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
