import { useState } from "react";
import {
  Progress,
  Chip,
  Chips,
  Title,
  Checkbox,
  Switch,
  Mark,
  Loader
} from "@mantine/core";
import FilterSection from "./filter-section.component";
import UploadDropzone from "./upload-dropzone.component";
import Explainer from "../explainer.component";
import Emoji from "../emoji.component";
import LoadingExcuses from "./loading-excuses.component";

export default function Upload() {
  const [isStarted, setIsStarted] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [words, setWords] = useState([]);
  const [progress, setProgress] = useState(0);
  const [showLoadingExplainer, setShowLoadingExplainer] = useState(false);

  const processFile = (url, fileType) => {
    let translationLang = translationLanguage;

    if (!textLanguage) {
      window.alert("Select the language of your text.");
      return;
    }
    if (!translationLanguage) {
      if (provideTranslations) {
        window.alert("Select the language for translations.");
      } else {
        translationLang = "en";
      }
    }

    const encodedUrl = encodeURIComponent(url);
    fetch(
      `${process.env.REACT_APP_API_URL}/mine/1?url=${encodedUrl}&text_language=${textLanguage}&file_type=${fileType}&translation_language=${translationLang}`,
      { method: "POST", redirect: "follow" }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsStarted(true);
        fetchWords(data.text_id);
        setTimeout(() => {if (progress < 20) {setShowLoadingExplainer(true)}}, 5000);
      })
      .catch((error) => window.alert(error));
  };

  const fetchWords = (textId) => {
    fetch(`${process.env.REACT_APP_API_URL}/mine/${textId}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 206) {
          response.json().then((data) => {
            setWords(data.words);
            setProgress(data.progress * 100);
          });
          setTimeout(() => fetchWords(textId), 1500);
        } else if (response.status === 200) {
          setIsProcessed(true);
          response.json().then((data) => {
            setWords(data.words);
            setProgress(data.progress * 100);
          });
        }
      })
      .catch((error) => window.alert(error));
  };

  const [textLanguage, setTextLanguage] = useState("");
  const [provideTranslations, setProvideTranslations] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState("");

  const textLanguages = [
    {
      code: "en",
      name: "English",
      symbol: "🇬🇧",
    },
    {
      code: "fr",
      name: "French",
      symbol: "🇫🇷",
    },
    {
      code: "es",
      name: "Spanish",
      symbol: "🇪🇸",
    },
    {
      code: "de",
      name: "German",
      symbol: "🇩🇪",
    },
    {
      code: "it",
      name: "Italian",
      symbol: "🇮🇹",
    },
    {
      code: "pt",
      name: "Portuguese",
      symbol: "🇵🇹",
    },
  ];

  return (
    <div>
      {isStarted && !isProcessed && !(textLanguage === "") && (
        <div className="w-screen">
          <Progress value={progress} size="xl" color="orange" radius="xs" />
          {progress === 0 && (
            <div className="flex flex-row justify-center items-center mt-4 space-x-2">
              <Loader color="gray" size="sm" />
              <p className="font-medium text-lg text-center">In the queue...</p>
            </div>
          )}
          {progress < 100 && showLoadingExplainer ? (
            <LoadingExcuses onClose={() => setShowLoadingExplainer(false)} />
          ) : (
            <div></div>
          )}
        </div>
      )}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {!isStarted && (
          <div className="mt-8">
            <Title order={1} className="mb-6">
              <Mark>Mine</Mark> <Emoji symbol="⛏" />
            </Title>
            {!isStarted && <Explainer />}
            <div className="pt-6 space-y-3 flex flex-col lg:items-center">
              <Title order={3}>Select the language of your text:</Title>
              <Chips
                value={textLanguage}
                onChange={setTextLanguage}
                color="orange"
                variant="filled"
                size="md"
                radius="sm"
              >
                {textLanguages.map((lang) => (
                  <Chip value={lang.code}>
                    {lang.name} <Emoji symbol={lang.symbol} />
                  </Chip>
                ))}
              </Chips>
            </div>

            {!(textLanguage === "") && (
              <div className="">
                <div className="py-6 space-y-3 flex flex-col lg:items-center">
                  <div className="flex flex-row space-x-4">
                    <Title order={3}>Would you like translations?</Title>
                    <Checkbox
                      label={<p className="text-lg font-bold">Yes</p>}
                      color="orange"
                      size="md"
                      checked={provideTranslations}
                      onChange={(event) =>
                        setProvideTranslations(event.currentTarget.checked)
                      }
                    />
                  </div>
                  {provideTranslations && (
                    <Chips
                      value={translationLanguage}
                      onChange={setTranslationLanguage}
                      color="orange"
                      variant="filled"
                      size="md"
                      radius="sm"
                    >
                      {textLanguages
                        .filter((e) => e.code !== textLanguage)
                        .map((lang) => (
                          <Chip value={lang.code}>
                            ...to {lang.name} <Emoji symbol={lang.symbol} />
                          </Chip>
                        ))}
                    </Chips>
                  )}
                </div>
                <UploadDropzone
                  onUpload={(url, fileType) => processFile(url, fileType)}
                  isTranslationLanguage={
                    (provideTranslations && translationLanguage !== "") ||
                    !provideTranslations
                  }
                />
              </div>
            )}
          </div>
        )}

        {words.length > 0 && (
          <FilterSection
            words={words}
            language={textLanguage}
            isTranslation={provideTranslations}
          />
        )}
      </div>
    </div>
  );
}
