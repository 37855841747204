import Emoji from "./emoji.component";
import ExplainerStep from "./explainer-step.component";

export default function Explainer() {
  return (
    <div className="max-w-5xl grid grid-rows space-y-4 sm:grid-cols-2 sm:space-x-6 sm:space-y-0">
      <div className="flex flex-col space-y-2 bg-violet-100 p-6 lg:p-8 rounded-md shadow-sm -mx-1">
        <ExplainerStep number="1" text="Select your language and upload a text" />
        <ExplainerStep number="2" text="Filter words and sentences from the text" />
        <ExplainerStep number="3" text="Copy the word info to your clipboard" />
      </div>
      <div>
        <div className="rounded-md bg-orange-100 p-4 shadow-sm">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-orange-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-base font-medium text-gray-700">
                <p className="font-bold">
                  Processing texts is crazy expensive{" "}
                  <Emoji symbol="🤯" label="poof" /> …so:
                </p>
                <ul className="mt-2 space-y-2">
                  <li>
                    ...you can process <b>up to ~10'000 words</b> (it cuts
                    off automatically) and upload texts of <b>up to 1MB</b>
                  </li>
                  <li>
                    ...if you need to process <b>larger texts</b>, you can{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://vocab.so/"
                      className="underline"
                    >
                      <b>join the beta</b>
                    </a>{" "}
                    (which is also free but limits the people uploading)
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="text-base font-medium"></div>
      </div>
    </div>
  );
}
