import { Highlight } from "@mantine/core";
import SingleRow from "./single-row.component";

export default function WordList({ words, isTranslation }) {
  const tableHeading =
    "pr-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider";
  return (
    <div className="flex flex-col pt-8 pb-16">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y-2 divide-gray-200">
              <thead>
                <tr>
                  <th scope="col" className={`sm:pl-6 ${tableHeading}`}>
                    Word
                  </th>
                  <th scope="col" className={tableHeading}>
                    POS
                  </th>
                  {isTranslation && (
                    <th scope="col" className={`pl-6 ${tableHeading}`}>
                      Translations
                    </th>
                  )}
                  <th scope="col" className={`px-6 ${tableHeading}`}>
                    EXAMPLES
                  </th>
                </tr>
              </thead>
              <tbody className="text-base font-medium divide-y divide-gray-200">
                {words.map((word) => (
                  <SingleRow
                    word={word}
                    key={word.word_pos_key}
                    isTranslation={isTranslation}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
