import Emoji from "../emoji.component";

export default function LoadingExcuses({ onClose }) {
  return (
    <div className="flex flex-row justify-center mt-6">
              <div className="max-w-3xl px-6">
      <div className="rounded-md bg-orange-100 p-4 shadow-sm">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-orange-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-base font-medium text-gray-700">
              <p className="font-bold">
                Why is this taking so long? <Emoji symbol=" 😵‍💫" />
              </p>
              <ul>
                <li>
                  ...for each word, the app is preloading a lot of{" "}
                  <b>information/attributes to filter by.</b>
                </li>
                <li>
                  ...the whole text gets <b>tokenized</b> first using a language
                  model: making it more accurate but slower.
                </li>
                <li>
                  ...if you are still reading this either the text is pretty
                  long or the server is currently receiving too many requests.
                  Don't refresh the page, it will come around eventually.
                </li>
              </ul>
            </p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button 
              
                type="button"
                onClick={onClose}
                className="inline-flex bg-orange-50 rounded-md p-1.5 text-orange-500 hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-50 focus:ring-orange-600"
              >
                <span className="sr-only">Dismiss</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
