import { Highlight, Tooltip, Mark } from "@mantine/core";
import { useState } from "react";
import { useClipboard } from "@mantine/hooks";

export default function SingleRow({ word, isTranslation }) {
  const [isCopyHover, setIsCopyHover] = useState(false);
  const [isExampleHover, setIsExampleHover] = useState(false);
  const [exampleIndex, setExampleIndex] = useState(0);
  const clipboard = useClipboard({ timeout: 1000 });
  return (
    <tr className="hover:bg-white hover:bg-opacity-50">
      <td className="py-2 sm:px-6">{word.word_root}</td>
      <td className="">{word.pos}</td>
      {isTranslation && (
        <td className="pl-6">
          {word.translations
            .slice(0, 5)
            .map((e) => e.word)
            .join(", ")}
        </td>
      )}
      <td
        className={`py-2 px-6 ${
          word.original_words_list.length > 1 && "hover:cursor-pointer"
        }`}
        onMouseEnter={() => setIsExampleHover(true)}
        onMouseLeave={() => setIsExampleHover(false)}
        onClick={() => {
          if (exampleIndex + 1 === word.original_words_list.length) {
            setExampleIndex(0);
          } else {
            setExampleIndex(exampleIndex + 1);
          }
        }}
      >
        <Tooltip
          color="gray"
          opened={isExampleHover && word.original_words_list.length > 1}
          label={`${word.original_words_list.length} examples`}
          position="top"
          placement="center"
          withArrow
        >
          <Highlight
            highlight={word.original_words_list[exampleIndex]["word_original"]}
          >
            {word.original_words_list[exampleIndex].sent}
          </Highlight>
        </Tooltip>
      </td>
      <td>
        <Tooltip
          color={clipboard.copied ? "green" : "gray"}
          opened={isCopyHover}
          label={clipboard.copied ? "Copied" : "Copy"}
          position="right"
          placement="end"
          withArrow
        >
          <button
            onMouseEnter={() => setIsCopyHover(true)}
            onMouseLeave={() => setIsCopyHover(false)}
            onClick={() =>
              clipboard.copy(
                `Word: ${word.word_root} (${word.pos})\n\nExample: ${
                  word.original_words_list[exampleIndex].sent
                }\n\nTranslations:${word.translations
                  .slice(0, 5)
                  .map((e) => " " + e.word)}`
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
          </button>
        </Tooltip>
      </td>
    </tr>
  );
}
