import WordList from "./word-list.component";
import {
  RangeSlider,
  Checkbox,
  Title,
  Group,
  SimpleGrid,
  Slider,
  Tooltip,
  CloseButton,
  Mark,
} from "@mantine/core";
import { useState } from "react";
import MoreInfo from "../more_info.component";

const posOptions = [
  "Adjectives",
  "Adverbs",
  "Verbs",
  "Pronouns",
  "Nouns",
  "Other",
];

const posCodeToPosName = {
  ADJ: "Adjectives",
  ADV: "Adverbs",
  AUX: "Verbs",
  VERB: "Verbs",
  PRON: "Pronouns",
  PROPN: "Nouns",
  NOUN: "Nouns",
  ADP: "Other",
  DET: "Other",
  INTJ: "Other",
  NUM: "Other",
  PART: "Other",
  PUNCT: "Other",
  SYM: "Other",
  X: "Other",
  SPACE: "Other",
  CONJ: "Other",
  CCONJ: "Other",
  SCONJ: "Other",
};

export default function FilterSection({ words, language, isTranslation }) {
  const [isFilterHidden, setIsFilterHidden] = useState(false);
  const [isWordList, setIsWordList] = useState(false);

  // Difficulty
  const [wordDifficultyValue, setWordDifficultyValue] = useState(0);
  const [wordGlobalFrequencyRange, setWordGlobalFrequencyRange] = useState([
    0, 40,
  ]);
  const [removeNotInDictionary, setRemoveNotInDictionary] = useState(false);
  const [removeNoTranslation, setRemoveNoTranslation] = useState(false);

  // Word Type
  const [activePOS, setActivePOS] = useState(posOptions);

  // Text Specific
  const [textFrequencyValue, setTextFrequencyValue] = useState(0);
  const [sentenceDifficultyValue, setSentenceDifficultyValue] = useState(0);
  const [sentenceLength, setSentenceLength] = useState([40, 200]);

  const filteredWords = words.filter(
    (word) =>
      word.word_difficulty > wordDifficultyValue &&
      (language !== "en" ||
        (wordGlobalFrequencyRange[0] * 100000 < word.global_frequency &&
          word.global_frequency < wordGlobalFrequencyRange[1] * 100000) ||
        (word.global_frequency < 0 && wordGlobalFrequencyRange[0] === 0) ||
        (word.global_frequency > 4000000 &&
          wordGlobalFrequencyRange[1] === 40)) &&
      (!removeNotInDictionary || word.in_dictionary) &&
      (!removeNoTranslation || word.translations.length > 0) &&
      activePOS.includes(posCodeToPosName[word.pos]) &&
      word.text_frequency > textFrequencyValue &&
      ((sentenceLength[0] < word.avg_sent_len &&
        word.avg_sent_len < sentenceLength[1]) ||
        (sentenceLength[1] === 200 && word.avg_sent_len > 200) ||
        (sentenceLength[0] === 40 && word.avg_sent_len < 40))
  );

  const filterBoxes = "flex-grow space-y-6";
  const singleFilter = "space-y-4";

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-row justify-between items-center pt-8 pb-4">
        <div className="flex flex-row items-center space-x-2">
          {!isFilterHidden && <Title order={2}>Filter</Title>}
          <Tooltip
            label={`Filtered words out of ${words.length} total words`}
            position="right"
            withArrow
          >
            <span
              className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                filteredWords.length / words.length < 0.05
                  ? "bg-red-200 text-red-900"
                  : "bg-pink-100 text-pink-800"
              }`}
            >
              {filteredWords.length} {isFilterHidden && "Words"}
            </span>
          </Tooltip>
        </div>
        <button
          className="p-3 rounded-md hover:bg-gray-100"
          onClick={() => setIsFilterHidden((o) => !o)}
        >
          {isFilterHidden ? (
            <div className="flex flex-row items-center space-x-2">
              <Title order={5}>Show Filter</Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                />
              </svg>
            </div>
          ) : (
            <div className="flex flex-row items-center ">
              <Title order={5}>Hide Filter</Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </button>
      </div>

      {!isFilterHidden && (
        <div className="flex flex-col sm:justify-between sm:flex-row space-y-8 sm:space-y-0 sm:space-x-8 lg:space-x-8 bg-gray-50 px-6 pt-6 pb-12 sm:px-8 sm:pt-8 rounded-md shadow-md">
          <div className={filterBoxes}>
            <Title order={3}>By Difficulty</Title>

            <div className={singleFilter}>
              <div className="flex items-center space-x-1">
                <Title order={4}>Word difficulty score</Title>
                <MoreInfo label="Sort by estimated difficulty of the word. 0 being easiest, 1 being most difficult." />
              </div>
              <Slider
                color="yellow"
                min={0}
                max={1}
                step={0.01}
                size="lg"
                radius="lg"
                value={wordDifficultyValue}
                onChange={setWordDifficultyValue}
              />
            </div>

            {language === "en" && (
              <div className={singleFilter}>
                <div className="flex items-center space-x-1">
                  <Title order={4}>Frequency across the web</Title>
                  <MoreInfo label="Sort by how frequently the word is used across a bunch of texts. This filter is only available for English ...so I guess ...lucky you?" />
                </div>
                <RangeSlider
                  color="yellow"
                  min={0}
                  max={40}
                  step={1}
                  defaultValue={[0, 40]}
                  size="lg"
                  radius="lg"
                  value={wordGlobalFrequencyRange}
                  onChange={setWordGlobalFrequencyRange}
                />
              </div>
            )}

            <div className={singleFilter}>
              <Title order={4}>Exclude words that...</Title>
              <div className="flex flex-row items-center space-x-1">
                <Checkbox
                  label={
                    <p className="text-lg font-medium">
                      are not in the dictionary
                    </p>
                  }
                  color="orange"
                  size="md"
                  checked={removeNotInDictionary}
                  onChange={(event) =>
                    setRemoveNotInDictionary(event.currentTarget.checked)
                  }
                />
                <MoreInfo label="Remove words that weren't found in the standard dictionary." />
              </div>

              <Checkbox
                label={
                  <p className="text-lg font-medium">
                    have no translations available
                  </p>
                }
                color="orange"
                size="md"
                checked={removeNoTranslation}
                onChange={(event) =>
                  setRemoveNoTranslation(event.currentTarget.checked)
                }
              />
            </div>
          </div>

          <div className={filterBoxes}>
            <Title order={3}>By Word Type</Title>

            <div className={singleFilter}>
              <Title order={4}>Part-of-speech</Title>
              <div className="grid grid-cols-2 gap-2 sm:grid-cols-1">
                {posOptions.map((option, i) => (
                  <Checkbox
                    key={i}
                    label={<p className="text-lg font-medium">{option}</p>}
                    checked={activePOS.includes(option)}
                    onChange={() => {
                      if (!activePOS.includes(option)) {
                        setActivePOS((o) => [...o, option]);
                      } else {
                        setActivePOS((o) => o.filter((e) => e !== option));
                      }
                    }}
                    color="orange"
                    size="md"
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={filterBoxes}>
            <Title order={3}>By Text Attributes</Title>

            <div className={singleFilter}>
              <div className="flex items-center space-x-1">
                <Title order={4}>Frequency in text</Title>
                <MoreInfo label="Sort by how often the word appears in the uploaded text." />
              </div>
              <Slider
                color="yellow"
                min={0}
                max={Math.max(words.length / 1000, 5)}
                step={1.0}
                size="lg"
                radius="lg"
                value={textFrequencyValue}
                onChange={setTextFrequencyValue}
              />
            </div>

            {/* <div className={singleFilter}>
            <div className="flex items-center space-x-1">
            <Title order={4}>Sentence difficulty score</Title>
            <MoreInfo label="Average difficulty score of all sentences the word appears in." />
          </div>
          <Slider
            min={0}
            max={1}
            step={0.01}
            size="lg"
            radius="xs"
            value={sentenceDifficultyValue}
            onChange={setSentenceDifficultyValue}
          /> 
          </div>*/}
            <div className={singleFilter}>
              <div className="flex items-center space-x-1">
                <Title order={4}>Sentence length</Title>
                <MoreInfo label="Average length of all sentences the word appears measured in characters." />
              </div>
              <RangeSlider
                radius="lg"
                color="yellow"
                min={40}
                max={200}
                step={10}
                defaultValue={[40, 200]}
                size="lg"
                value={sentenceLength}
                onChange={setSentenceLength}
              />
            </div>
          </div>
        </div>
      )}

      {filteredWords.length > 0 ? (
        <WordList
          words={filteredWords}
          isTranslation={isTranslation}
          isWordList={isWordList}
        />
      ) : (
        /* TODO */
        <div className="text-center py-6 sm:py-12 font-medium text-base">
          No words available for current filter
        </div>
      )}
    </div>
  );
}
