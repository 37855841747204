// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJFPjClOzfLRHGnpqnclk942_VKZos5h0",
  authDomain: "vocably-66cac.firebaseapp.com",
  projectId: "vocably-66cac",
  storageBucket: "vocably-66cac.appspot.com",
  messagingSenderId: "550955182620",
  appId: "1:550955182620:web:d44dbb00e27bcf29984dd9",
  measurementId: "G-J71L24DWTX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);